<template>
    <div :class="[
        'CEESAR-ScenarioCardList',
        withDetails? 'CEESAR-ScenarioCardList-Details' : ''
    ]">
        <div class="CEESAR-ScenarioCardListLabel" v-on:click="$emit('click')">
            {{label}}
        </div>
        <div class="CEESAR-ScenarioCardListContent" v-if="withDetails">
            <div class="CEESAR-ScenarioCardListLine" v-for="item in items" v-bind:key="item.id" >
                {{item.label}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-ScenarioCardList",
    props:{
        label: {type: String, default: undefined},
        items: {type: Array, default: undefined},
        withDetails: {type: Boolean, default: false}
    },
}
</script>

<style lang="less">
    .CEESAR-ScenarioCardList{
        border-bottom: 2px solid darkorange;

        &:last-of-type{
            border-bottom: none;
        }

        .CEESAR-ScenarioCardListLabel{
            transition: color 0.3s ease-in-out;

            font-weight: bold;
            white-space: nowrap;
            cursor: pointer;

            display: flex;
            flex-flow: row;

            &::after{
                transition: transform 0.1s ease-in-out;
                content:"▼";
                transform-origin: center center;
            }

            &:hover{
                color: darkorange;
            }
            &:active{
                color: darkorange;
            }
        }

        .CEESAR-ScenarioCardListContent{
            padding-left: 10px;
        }
        .CEESAR-ScenarioCardListLine{
            white-space: nowrap;
            &::before{
                color: darkorange;
                content:"●";
            }
        }
    }

    .CEESAR-ScenarioCardList-Details{
        .CEESAR-ScenarioCardListLabel{
            &::after{
                color: darkorange;
                transform: rotateZ(180deg);
            }
        }
    }
</style>